

      let swiper = new Swiper(".mySwiper", {
        spaceBetween: 30,
        effect: "fade",
        autoplay: {
          delay: 7000,
          disableOnInteraction: true,      
      },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });

      swiper.on('slideChange', function () {
        localStorage.setItem("slide_pos", this.realIndex);
      });

      const iframe = document.querySelector('iframe')
      const player = new Vimeo.Player(iframe);
      player.on('play', function() {
        swiper.autoplay.stop();
      });

